import request from '@/utils/request'


// 查询研学分类列表
export function listClassify(query) {
  return request({
    url: '/study/classify/list',
    method: 'get',
    params: query
  })
}

// 查询研学分类分页
export function pageClassify(query) {
  return request({
    url: '/study/classify/page',
    method: 'get',
    params: query
  })
}

// 查询研学分类详细
export function getClassify(data) {
  return request({
    url: '/study/classify/detail',
    method: 'get',
    params: data
  })
}

// 新增研学分类
export function addClassify(data) {
  return request({
    url: '/study/classify/add',
    method: 'post',
    data: data
  })
}

// 修改研学分类
export function updateClassify(data) {
  return request({
    url: '/study/classify/edit',
    method: 'post',
    data: data
  })
}

// 删除研学分类
export function delClassify(data) {
  return request({
    url: '/study/classify/delete',
    method: 'post',
    data: data
  })
}
